define('ember-component-css/initializers/component-styles', ['exports', 'ember-component-css/pod-names'], function (exports, _emberComponentCssPodNames) {
  'use strict';

  exports.initialize = initialize;

  var ComponentLookup = Ember.ComponentLookup;

  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = owner.hasRegistration ? owner : Ember.getOwner(this);

      if (_emberComponentCssPodNames['default'][name] && !owner.hasRegistration('component:' + name)) {
        owner.register('component:' + name, Ember.Component);
      }
      return this._super.apply(this, arguments);
    }
  });

  Ember.Component.reopen({
    _componentIdentifier: Ember.computed({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),

    _shouldAddNamespacedClassName: Ember.computed({
      get: function get() {
        return this.get('tagName') !== '' && this.get('styleNamespace');
      }
    }),

    styleNamespace: Ember.computed({
      get: function get() {
        return _emberComponentCssPodNames['default'][this.get('_componentIdentifier')] || '';
      }
    }),

    // componentCssClassName: deprecatingAlias('styleNamespace', {
    //   id: 'ember-component-css.deprecate-componentCssClassName',
    //   until: '0.7.0',
    // }),

    componentCssClassName: Ember.computed.alias('styleNamespace'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('_shouldAddNamespacedClassName')) {
        this.classNames = this.classNames.concat(this.get('styleNamespace'));
      }
    }
  });

  function initialize() {}

  exports['default'] = {
    initialize: initialize
  };
});