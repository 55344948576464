define('ember-component-css/initializers/route-styles', ['exports', 'ember-component-css/pod-names'], function (exports, _emberComponentCssPodNames) {
  'use strict';

  exports.initialize = initialize;

  Ember.Router.reopen({
    didTransition: function didTransition(routes) {
      this._super.apply(this, arguments);

      var classes = [];
      for (var i = 0; i < routes.length; i++) {
        var route = routes[i];
        var currentPath = route.name.replace(/\./g, '/');

        if (_emberComponentCssPodNames['default'][currentPath]) {
          Ember.getOwner(this).lookup('controller:' + route.name).set('styleNamespace', _emberComponentCssPodNames['default'][currentPath]);
          classes.push(_emberComponentCssPodNames['default'][currentPath]);
        }
      }

      Ember.getOwner(this).lookup('controller:application').set('routeStyleNamespaceClassSet', classes.join(' '));
    }
  });

  function initialize() {}

  exports['default'] = {
    initialize: initialize
  };
});