define('ember-cli-bootstrap-switch/components/bs-switch', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var get = //jshint ignore: line
  //jshint ignore: line
  _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var //jshint ignore: line
  assert = _ember['default'].assert;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var //jshint ignore: line
  observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  var //jshint ignore: line
  isBlank = _ember['default'].isBlank;
  var //jshint ignore: line
  isEmpty = _ember['default'].isEmpty;
  var //jshint ignore: line
  isEqual = _ember['default'].isEqual;
  var $ = _ember['default'].$;

  var bsSwitchComponent = Component.extend({

    tagName: 'div',
    classNames: ['bs-switch'],

    btnSize: 'small',
    name: 'bs-switch',
    disabled: false,
    onColor: 'primary', // 'primary', 'info', 'success', 'warning', 'danger', 'default'
    offColor: 'default', // 'primary', 'info', 'success', 'warning', 'danger', 'default'
    onText: 'ON',
    offText: 'OFF',
    labelText: '',

    // Insternal state.
    status: false,

    willDestroyElement: function willDestroyElement() {
      this.$('input').bootstrapSwitch('destroy');
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      // Ensure bootstrap-switch is loaded...
      assert("bootstrap-switch has to exist on Ember.$.fn.bootstrapSwitch", typeof _ember['default'].$.fn.bootstrapSwitch === "function");

      this.$('input').bootstrapSwitch({
        "size": get(this, 'btnSize'),
        "state": get(this, 'status'),
        "disabled": get(this, 'disabled'),
        "onText": get(this, 'onText'),
        "offText": get(this, 'offText'),
        "onColor": get(this, 'onColor'),
        "offColor": get(this, 'offColor'),
        "labelText": get(this, 'labelText')
      });

      this.$('input').on('switchChange.bootstrapSwitch', function (event, state) {
        // console.log(this); // DOM element
        // console.log(event); // jQuery event
        // console.log(state); // true | false
        set(_this, 'status', state);
        _this.send('sendActionToConsumer', state);
      });
    },

    /**
     * Observes status for outside changes in order to update the plugin value.
     *
     * @method statusObserver
     * @private
     */
    _statusObserver: observer('status', function () {
      this.$('input').bootstrapSwitch('state', get(this, 'status'), true);
    }),

    actions: {
      sendActionToConsumer: function sendActionToConsumer(_state) {
        this.sendAction('callback', {
          "name": get(this, 'name'),
          "statusValue": _state
        });
      }
    }

  });

  exports['default'] = bsSwitchComponent;

  //jshint ignore: line
});