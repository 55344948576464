define("ember-truth-helpers/helpers/is-array", ["exports"], function (exports) {
  "use strict";

  exports.isArray = isArray;

  function isArray(params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if (Ember.isArray(params[i]) === false) {
        return false;
      }
    }
    return true;
  }

  exports["default"] = Ember.Helper.helper(isArray);
});