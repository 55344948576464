define('ember-cli-google-recaptcha/components/g-recaptcha-v2', ['exports', 'ember-cli-google-recaptcha/-private/g-recaptcha-base'], function (exports, _emberCliGoogleRecaptchaPrivateGRecaptchaBase) {
  'use strict';

  exports['default'] = _emberCliGoogleRecaptchaPrivateGRecaptchaBase['default'].extend({
    classNames: ['g-recaptcha--v2'],

    theme: 'light',

    type: 'image',

    size: 'normal',

    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      if (this.get('reset')) {
        this._reset();
      }
    }
  });
});